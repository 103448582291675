<template>
  <div
    class="timeline-time_container margin margin-medium margin-bottom"
    :class="stateString(false)"
  >
    <div class="information-side margin margin-medium margin-left">
      <h6 class="normal-semi">
        {{ stateString(true) }}
      </h6>
      <p class="normal-regular timestring">
        {{ availabilityString }}
      </p>
    </div>
    <div
      v-if="hasUser"
      class="user-side overflow-hidden"
    >
      <h6 class="elipsis">
        {{ booking.User.Name }}
      </h6>
      <person-avatar
        height="40px"
        width="40px"
        :src="booking.User.AvatarUrl"
      />
    </div>
  </div>
</template>

<script>
import ExtendedDate from '@/classes/extended.date.class';
import ZoneState from '@/singletons/zone.state.singleton';
import PersonAvatar from '@/components/globals/Person/PersonAvatar.vue';

export default {
  components: {
    PersonAvatar,
  },
  props: {
    booking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      state: ZoneState.ZONE_STATE,
    };
  },
  computed: {
    availabilityString() {
      const hh1 = new ExtendedDate(this.booking.From).getHours();
      const hh2 = new ExtendedDate(this.booking.Until).getHours();
      const mm1 = new ExtendedDate(this.booking.From).getMinutes();
      const mm2 = new ExtendedDate(this.booking.Until).getMinutes();
      if (hh2 === 0 && mm2 === 0) return this.$t('Views.Book.whole_of_day');
      return (hh1 === hh2 && mm1 === mm2) ? this.$t('Views.Book.whole_of_day') : this.timeString;
    },
    timeString() {
      const from = new ExtendedDate(this.booking.From);
      const until = new ExtendedDate(this.booking.Until);
      return `${from.localeTimeString()} - ${until.localeTimeString()}`;
    },
    stateString() {
      return (i18n) => this.getStateString(i18n);
    },
    hasUser() {
      return !!this.booking.User;
    },
    status() {
      const fromTime = ZoneState.activeFrom || new ExtendedDate().getTime();
      if (!this.hasUser) return this.state.AVAILABLE;
      if (this.booking.Until > fromTime && this.booking.From < fromTime) return ZoneState.zoneStates[this.booking.Zid]?.Status;
      return this.state.AWAY;
    },
  },
  methods: {
    getStateString(i18n = false) {
      switch (this.status) {
        case this.state.UNKNOWN:
          return i18n ? this.$t('Views.Book.unbookable') : 'unbookable';
        case this.state.AVAILABLE:
          return i18n ? this.$t('Views.Book.available') : 'available';
        case this.state.AWAY:
          return i18n ? this.$t('Views.Book.booked') : 'booked';
        case this.state.OCCUPIED:
          return i18n ? this.$t('Views.Book.occupied') : 'occupied';
        default:
          return i18n ? this.$t('Views.Book.unbookable') : 'unbookable';
      }
    },
  },
};
</script>

<style lang="scss">

.timestring {
  width: 120px;
}

.timeline-time_container{
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  &.occupied{
    border-left: 8px solid var(--occupied-color);
  }
  &.booked{
    border-left: 8px solid var(--away-color);
  }
  &.available{
    border-left: 8px solid var(--free-color);
  }
  &.unbookable{
    border-left: 8px solid var(--color-resource-other);
  }
  .information-side {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h5{
      margin: auto;
    }
    p,h6{
      margin: 0;
      white-space: nowrap;
    }
  }
  .user-side{
    display: flex;
    gap: 0.5rem;
    text-align: right;
    max-width: 50%;
    *{
      margin: auto;
    }
  }
}

</style>

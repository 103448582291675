<template>
  <div>
    <div
      v-if="!resource.Bookable"
      class="timeline-time_container margin margin-medium margin-bottom"
      :class="resourceStatus(false)"
    >
      <div class="information-side margin margin-medium margin-left">
        <h5>{{ resourceStatus(true) }}</h5>
      </div>
      <div
        v-if="user.Name && !resourceAvailable"
        class="user-side overflow-hidden"
      >
        <h6 class="elipsis">
          {{ user.Name }}
        </h6>
        <person-avatar
          height="40px"
          width="40px"
          :src="user.AvatarUrl"
        />
      </div>
    </div>
    <div v-else>
      <div
        v-for="(booking, index) in bookings"
        :key="index"
      >
        <TimelineItem
          v-if="showAll || index < 2"
          :booking="booking"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ZoneState from '@/singletons/zone.state.singleton';
import ZoneStateService from '@/Services/Zones/zone.state.service';
import SearchService from '@/Services/Search';
import TimelineItem from './booking.timeline.item.vue';

export default {
  components: {
    TimelineItem,
  },
  props: {
    bookings: {
      type: Array,
      required: true,
    },
    resource: {
      type: Object,
      required: true,
    },
    showAll: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      status: ZoneState.ZONE_STATE,
      userData: null,
      SearchService,
    };
  },
  computed: {
    resourceStatus() {
      return (i18n) => this.getStateString(i18n);
    },
    resourceAvailable() {
      return this.resourceStatus(true) === this.$t('Views.Book.available');
    },
    state() {
      return ZoneState.zoneStates[this.resource.Zid]?.Status;
    },
    user() {
      const state = ZoneStateService.getRealmStateForZone(this.resource.Zid);
      const email = state?.Properties?.UName;

      if (!email) return {};
      if (email !== this.userData?.Username && !this.resource?.Bookable) {
        this.fetchUserData(email);
      }
      if (this.userData) {
        const { Name, AvatarUrl } = this.userData;
        return { Name, AvatarUrl };
      }
      return { Name: this.$t('Views.Book.guest'), AvatarUrl: null };
    },
  },
  methods: {
    async fetchUserData(email) {
      this.userData = await SearchService.getColleague(email);
    },
    getStateString(i18n = false) {
      switch (this.state) {
        case this.status.AVAILABLE:
          return i18n ? this.$t('Views.Book.available') : 'available';
        case this.status.AWAY:
          return i18n ? this.$t('Views.Book.booked') : 'booked';
        case this.status.OCCUPIED:
          return i18n ? this.$t('Views.Book.occupied') : 'occupied';
        case this.status.UNKNOWN:
        default:
          return i18n ? this.$t('Views.Book.available') : 'unbookable';
      }
    },
  },
};
</script>
<style lang="scss">

.timeline-time_container{
  display: flex;
  justify-content: space-between;
  height: 3.5rem;
  &.occupied{
    border-left: 8px solid var(--occupied-color);
  }
  &.booked{
    border-left: 8px solid var(--away-color);
  }
  &.available{
    border-left: 8px solid var(--free-color);
  }
  &.unbookable{
    border-left: 8px solid var(--color-resource-other);
  }
  .information-side{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    h5{
      margin: auto;
    }
    p,h6{
      margin: 0;
    }
  }
  .user-side{
    display: flex;
    gap: 1rem;
    *{
      margin: auto;
    }
  }
}
</style>

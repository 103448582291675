/* eslint-disable class-methods-use-this */
import CompanyState from '@/singletons/company.state.singleton';
import SearchManager from './SearchManager';
import { getUserSmall } from '../../util/api';

const filterOutZoneType = (zone, type) => zone.Type !== type;
const filterOutNoParentZone = (zid) => !!CompanyState.zones.find(({ Zid }) => zid === Zid)?.ParentZone;

class Search {
  constructor() {
    this.isActive = false;
    this.searchText = '';
    this.searchOptions = {};
    this.searchResults = {};
    this.maxResults = 10;
    this.showOnly = null;
    this.colleagues = null;
  }

  init() {
    // console.log('Initiating search object');
  }

  async getColleague(email) {
    try {
      /* Get cached user from colleagues if exist */
      if (this.colleagues) {
        const [user] = this.colleagues?.Users.filter(({ Username }) => Username === email);
        if (user) return user;
      }
      const { data } = await getUserSmall(email);
      return data;
    } catch (e) {
      /* 404 if user does not exist, return null */
      return null;
    }
  }

  async fetchNewSearchOptions() {
    this.colleagues = await SearchManager.getColleagues();
    this.filterSearchOptions();
  }

  filterSearchOptions() {
    if (this.showOnly) {
      this.searchOptions = this.colleagues[this.showOnly];
    } else {
      const { Zones, Users } = this.colleagues;
      const wantedZones = Zones.filter((zone) => filterOutZoneType(zone, 'FLOOR') && filterOutZoneType(zone, 'SEAT') && filterOutNoParentZone(zone.Zid));
      this.searchOptions.Users = Users;
      this.searchOptions.Zones = wantedZones;
    }
  }

  setSearchText(text) {
    this.searchText = text;
    // console.log(text);
    if (!this.searchText) return;
    this.sortResults();
  }

  setMaxResults(max) {
    this.maxResults = max;
  }

  getZoneByZid(zid) {
    const zone = this.searchOptions.Zones.find((option) => option.Zid === zid);

    return zone;
  }

  sortResults() {
    // Users
    this.searchResults.Users = this.searchOptions.Users.filter((option) => {
      const textSum = option.Name + option.Username;
      return textSum.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.searchResults.Users.sort((a, b) => {
      const textSumA = a.Name + a.Username;
      const textSumB = b.Name + b.Username;
      return textSumA > textSumB ? 1 : -1;
    });
    if (this.searchResults.Users.length > 10) {
      this.searchResults.Users = this.searchResults.Users.slice(0, this.maxResults);
    }

    // Zones
    this.searchResults.Zones = this.searchOptions.Zones.filter((option) => {
      const textSum = option.Name;
      return textSum.toLowerCase().includes(this.searchText.toLowerCase());
    });
    this.searchResults.Zones.sort((a, b) => {
      const textSumA = a.Name;
      const textSumB = b.Name;
      return textSumA > textSumB ? 1 : -1;
    });
    if (this.searchResults.Zones.length > 10) {
      this.searchResults.Zones = this.searchResults.Zones.slice(0, this.maxResults);
    }
  }

  destroy() {
    this.isActive = false;
    this.searchText = '';
  }
}

export default new Search();

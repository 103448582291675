/* eslint-disable object-shorthand */

import UserState from '@/singletons/user.state.singleton';
import WeekState from '@/singletons/week.state.singleton';
import ExtendedDate from '@/classes/extended.date.class';
import { getBookingSuggestions } from '@/util/api';
import BookingService from '@/Services/Bookings/bookings.service';
import i18n from '../../i18n';

class BookingSuggestionsService {
  //
  static async getSuggestions(zid) {
    const { date } = WeekState;
    const dateStr = new ExtendedDate(date).getDateString();
    const { data } = await getBookingSuggestions(dateStr, zid);

    const suggestions = [];
    data.suggestions.forEach((item) => {
      suggestions.push(BookingSuggestionsService.createSuggestionWithStrings(item));
    });
    return {
      suggestions: suggestions,
      apiResponse: data,
    };
  }

  static createSuggestionWithStrings(apiSuggestion) {
    const suggestion = apiSuggestion;
    suggestion.time = BookingSuggestionsService.getTimeString(
      apiSuggestion.from,
      apiSuggestion.until,
    );

    switch (apiSuggestion.type) {
      case 'next_hour': {
        suggestion.title = i18n.t('Views.Book.next_hour');
        break;
      }
      case 'next_half_hour': {
        suggestion.title = i18n.t('Views.Book.next_half_hour');
        break;
      }
      case 'whole_day': {
        suggestion.title = i18n.t('Views.Book.rest_of_day');
        break;
      }
      default:
        suggestion.title = '-';
        break;
    }
    return suggestion;
  }

  static getTimeString(d1, d2) {
    return `${new ExtendedDate(d1).localeTimeString()} - ${new ExtendedDate(d2).localeTimeString()}`;
  }

  static async createBooking(suggestion, zid) {
    const MIN_15 = 1000 * 60 * 15;
    const fromTimeStamp = new ExtendedDate(suggestion.from).getTime();
    const checkin = ((fromTimeStamp - MIN_15) > Date.now()) ? Date.now() : null;

    const booking = {
      Owner: UserState.username,
      From: suggestion.from,
      CheckIn: checkin,
      Until: suggestion.until,
      Zid: zid,
      Subject: 'Quick booking',
      Desc: null,
      Attendee: [],
      Source: 'userweb',
    };
    const { data } = await BookingService.hardPostBooking(booking);
    return data;
  }
}

export default BookingSuggestionsService;

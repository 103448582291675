/* eslint-disable no-return-assign */
import ColleagueState from '@/singletons/colleague.state.singleton';
import { getColleagues } from '../../util/api';

class SearchManager {
  static async getColleagues() {
    const { data } = await getColleagues();
    return ColleagueState.colleagues = data;
  }
}

export default SearchManager;

<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="backText"
      :route="route"
      :show-resource-info="true"
      :title="resourceName"
    >
      <h5 class="header-title">
        {{ resourceName }}
      </h5>
      <div
        class="booking-information--container"
        @click="navigateToInformation"
      >
        <svg-icon
          src="/icons/information_circle.svg"
        />
      </div>
    </Header>
    <div class="scrollable navigator-booking-wrapper">
      <!-- Date selector -->
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>

      <Card
        v-if="resource && !resource.Bookable"
        position="bottom"
      >
        <BookingTimeline
          v-if="loaded && ZoneState.hasLoadedOnce"
          :bookings="bookings"
          :resource="resource"
          :show-all="expanded"
        />
      </Card>

      <!-- Unbookable text -->
      <Card v-if="!resource.Bookable && getInfoString()">
        <p>{{ getInfoString() }}</p>
      </Card>

      <div v-if="resource.Bookable">
        <!-- Organizer -->
        <Card>
          <div class="booking_items_container">
            <div class="booking_flex_container">
              <PersonAvatar
                v-if="UserState.hasLoadedOnce && bookingTemplate.Owner"
                :src="UserState.avatar"
                height="50px"
                width="50px"
                :border="false"
                :user="bookingTemplate.Owner"
              />
              <div class="orgianizer_container overflow-hidden">
                <p class="normal-semi elipsis">
                  {{ bookingTemplate.Owner ? bookingTemplate.Owner.Name : $t('Components.BookingDetails.no_organizer') }}
                </p>
                <p
                  v-if="bookingTemplate.Owner"
                  class="small-regular"
                >
                  {{ $t('Components.BookingDetails.organizer') }}
                </p>
              </div>
            </div>
            <Button
              class="button change-organizer-button"
              size="tiny"
              variant="pastel-primary"
              @click="navigateToOrganizer"
            >
              {{ $t('Views.Book.change') }}
            </Button>
          </div>
        </Card>

        <!-- Title -->
        <Card>
          <p class="margin margin-bottom normal-semi">
            {{ $t('Views.Book.title') }}
          </p>
          <BFormInput
            v-model="bookingTemplate.Subject"
            :placeholder="$t('Views.Book.quick_booking')"
          />
        </Card>

        <!-- Suggestions -->
        <Card v-if="resource.Bookable">
          <SuggestionsComponent
            :disabled="!bookingTemplate.Owner"
            :emit-suggestion="true"
            :zone="resource"
            @onSuggestedBooking="createBooking"
          />
        </Card>

        <!-- Accordion custom booking -->
        <Card
          v-if="resource"
          :position="expanded ? 'bottom' : ''"
          class="accordion-container"
        >
          <div
            class="booking_items_container"
            @click="toggleCustomBooking"
          >
            <div class="booking_flex_container">
              <p class="normal-semi elipsis">
                {{ $t('Views.Book.more_booking_options') }}
              </p>
            </div>
            <div class="icon-holder">
              <svg-icon
                :src="expanded ? '/icons/arrow_up.svg' : '/icons/arrow_down.svg'"
              />
            </div>
          </div>
        </Card>

        <!-- Custom booking -->
        <div v-show="expanded">
          <!-- Attendees -->
          <Card class="no-padding-top">
            <div class="booking_items_container">
              <p class="normal-semi">
                {{ $t('Views.Book.invited') }}
              </p>
              <Button
                class="booking_items_container--icon"
                variant="pastel-primary"
                size="tiny"
                @click="navigateToAttendees"
              >
                <svg-icon
                  class="resource-icon_edit"
                  src="/icons/invited_users_icon.svg"
                />
                {{ nrOfInvited }}
              </Button>
            </div>
          </Card>

          <!-- Private -->
          <Card>
            <div class="booking_items_container">
              <p class="normal-semi">
                {{ $t('Views.Book.private') }}
              </p>
              <BFormCheckbox
                v-model="bookingTemplate.Private"
                switch
              />
            </div>
          </Card>

          <!-- Timepicker -->
          <Card>
            <Timepicker
              :start-date="from"
              :end-date="until"
              :zid="resourceZid"
            />
          </Card>
        </div>

        <!-- Create booking button -->
        <Card
          position="bottom"
        >
          <div class="flex flex-gap">
            <Button
              variant="danger"
              style="margin:auto;"
              @click="cancelBooking"
            >
              {{ $t('Components.BookingTab.cancel_booking') }}
            </Button>
            <Button
              v-if="!hideBookButton"
              variant="primary"
              style="margin:auto;"
              :disabled="bookButtonDisabled"
              @click="createBooking"
            >
              {{ $t('Views.Book.book') }}
            </Button>

            <div
              v-else
              class="spinner-medium"
            />
          </div>
        </Card>
      </div>
    </div>
  </div>
</template>

<script>
import WeekState from '@/singletons/week.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import ActiveZoneBookings from '@/classes/active.zone.bookings.class';
import BookingService from '@/Services/Bookings/bookings.service';

/** Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';
import SocketService from '@/Services/Socket/socket.service';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import UserService from '@/Services/User/user.service';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import UserState from '@/singletons/user.state.singleton';
import TimepickerController from '@/components/timepicker/timepicker.controller';
import Timepicker from '@/components/timepicker/timepicker.vue';
import CustomEvent from '@/classes/custom.event.class';
import ErrorMessage from '@/Services/Error/Error';
import { BFormCheckbox, BFormInput } from 'bootstrap-vue';
import { createQueryStringFromObject } from '@/functions/filter.query.functions';
import BookingTimeline from '@/views/book/components/booking.timeline.vue';
import MapNavigatorController from '@/classes/map/map.navigator.controller';
import { SET_USER_IDLE } from '@/views/utility/components/user.idle.vue';
import bookingTemplate from './template.booking';
import ExtendedDate from '../../classes/extended.date.class';
import SuggestionsComponent from './components/booking.suggestions.vue';
import QueryMixin from '../../mixins/query.mixin';

export default {
  components: {
    BookingTimeline,
    BFormInput,
    BFormCheckbox,
    Header,
    Card,
    Button,
    SuggestionsComponent,
    WeeklyDateSelector,
    Timepicker,
  },
  mixins: [QueryMixin],
  data() {
    return {
      ZoneState,
      WeekState,
      UserState,
      bookingTemplate,
      status: ZoneState.ZONE_STATE,
      bookings: [],
      loaded: false,
      expanded: false,
      eventListeners: [],
      bookingNotifications: [],
      hideBookButton: false,
    };
  },
  computed: {
    nrOfInvited() { return bookingTemplate.Attendees?.length || this.$t('Views.Book.invite_colleague'); },
    resourceZid() { return Number(this.$route.params.zid); },
    keepTimeSelection() { return !!bookingTemplate.From; },
    from() { return this.keepTimeSelection ? new ExtendedDate(bookingTemplate.From) : new ExtendedDate(WeekState.date); },
    until() { return this.keepTimeSelection ? new ExtendedDate(bookingTemplate.Until) : null; },
    resourceName() { return this.resource?.Name || this.$t('Views.Book.book'); },
    resource() { return ZoneState.activeResources[0]; },
    bookable() { return this.resource?.Bookable; },
    backText() { return this.resource?.TypeName; },
    route() { return `/book/${this.$route.params.type}?ignoreType=false`; },
    bookButtonDisabled() {
      return !TimepickerController.endTimstamp() || bookingTemplate.Owner === null;
    },
  },
  watch: {
    resource() {
      if (this.bookable) {
        this.setUpActiveZoneBookings();
      } else {
        this.bookings = [];
      }
    },
    $route() {
      if (bookingTemplate.Zid === this.$route.params.zid) {
        return;
      }
      bookingTemplate.Zid = this.$route.params.zid;
      this.setUpActiveZoneBookings();
      this.updateBookingTemplateTime();

      this.zid = this.$route.params.zid;
    },
  },
  async created() {
    this.replaceQuery({ date: WeekState.date.getDateString() });
    this.setUpBookingTemplate();
    await this.weekChanged(WeekState.date);
    this.expanded = bookingTemplate.expandCustomBooking;
  },
  destroyed() {
    if (!this.$route.params?.keepTemplate && !this.keepTemplate) {
      this.resetBookingData();
    }
  },
  mounted() {
    const { zid } = this.$route.params || 0;
    this.zid = Number(zid);
    if (this.bookable) {
      this.setUpActiveZoneBookings();
      this.setUpBookingEventHandlers();
    }

    this.loaded = true;
  },
  beforeDestroy() {
    this.eventListeners.forEach((l) => {
      window.removeEventListener(l.type, l.listener);
    });
    this.eventListeners = [];
  },
  methods: {
    navigateToInformation() {
      this.$router.push(`/book/${this.$route.params.type}/${this.$route.params.zid}/information?${createQueryStringFromObject(this.$route.query)}`);
      // this.$router.push({ path: `${this.$route.path}/information`, query: this.$route.query });
    },
    getInfoString() {
      switch (ZoneState.zoneStates[this.resource.Zid]?.Status) {
        case this.status.AVAILABLE:
          return this.$t('Views.Book.available_to_use');
        case this.status.AWAY:
          return this.$t('Views.Book.not_available_to_use');
        case this.status.OCCUPIED:
        case this.status.UNKNOWN:
        default:
          return '';
      }
    },
    toggleCustomBooking() {
      this.expanded = !this.expanded;
      bookingTemplate.expandCustomBooking = this.expanded;
    },
    setUpBookingTemplate() {
      if (bookingTemplate.templateFor !== 'customBooking') this.resetBookingData();
      bookingTemplate.templateFor = 'customBooking';
      bookingTemplate.Attendees = bookingTemplate.Attendees || [];
      bookingTemplate.Zid = this.$route.params.zid;
      bookingTemplate.Source = 'userweb:floorplan';
    },
    resetBookingData() {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key] of Object.entries(bookingTemplate)) {
        bookingTemplate[key] = null;
      }
    },
    setUpBookingEventHandlers() {
      window.addEventListener(SocketService.events.booking_created, this.createHandle);
      window.addEventListener(SocketService.events.booking_deleted, this.deleteHandle);
      window.addEventListener(SocketService.events.booking_state, this.changeHandle);

      this.eventListeners.push({ type: SocketService.events.booking_created, listener: this.createHandle });
      this.eventListeners.push({ type: SocketService.events.booking_deleted, listener: this.deleteHandle });
      this.eventListeners.push({ type: SocketService.events.booking_state, listener: this.changeHandle });
    },
    navigateToOrganizer() {
      this.updateBookingTemplateTime();
      this.keepTemplate = true;
      this.$router.push({ name: 'book-organizer', query: this.query });
    },
    navigateToAttendees() {
      this.updateBookingTemplateTime();
      this.keepTemplate = true;
      this.$router.push({ name: 'book-attendees', query: this.query });
    },
    updateBookingTemplateTime() {
      const { date } = WeekState;
      let from = TimepickerController.startTimestamp();
      let until = TimepickerController.endTimstamp();

      from = (from && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(from) : date;
      until = (from && until && from !== until) ? new ExtendedDate(date).setHours(0, 0, 0, 0) + Number(until) : null;

      bookingTemplate.From = from < Date.now() ? Date.now() : from;
      bookingTemplate.Until = until;

      const MIN_15 = 1000 * 60 * 15;
      const now = new ExtendedDate().getTime();
      if ((from - now) < MIN_15) bookingTemplate.CheckIn = from;
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
      bookingTemplate.From = null;
      bookingTemplate.Until = null;
      this.setUpActiveZoneBookings(this.zid);
      this.updateQuery({ date: WeekState.date.getDateString() });
    },
    dateChanged(date) {
      WeekState.date = date;
      bookingTemplate.From = null;
      bookingTemplate.Until = null;
      this.setUpActiveZoneBookings(this.zid);
      this.updateQuery({ date: WeekState.date.getDateString() });
    },
    async createBooking(suggestion) {
      this.hideBookButton = true;

      try {
        // Set booking time
        this.updateBookingTemplateTime();
        // Shallow copy to modify booking data to make it go through validation
        const booking = { ...bookingTemplate };
        if (!booking.Subject) {
          booking.Subject = this.$t('Views.Book.custom_booking');
        }
        if (suggestion?.from && suggestion?.until) {
          booking.From = suggestion.from;
          booking.Until = suggestion.until;
        }
        // Change to owner to only username
        booking.Owner = bookingTemplate.Owner.Username;
        if (booking.Owner !== UserState.username) booking.Source = `onbehalf:${UserState.username}`;
        await BookingService.hardPostBooking(booking);
        const evt = new CustomEvent(this.$t('Messages.created_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$root.$emit(SET_USER_IDLE);
      } catch (e) {
        let evtMsg;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }

      this.hideBookButton = false;
    },
    cancelBooking() {
      if (this.$route.name !== 'search') {
        // Avoid redundant route
        this.$router.push({ name: 'search' });
      }

      MapNavigatorController.reset();
    },
    async setUpActiveZoneBookings() {
      const { zid } = this.$route.params;
      const selectedDate = new ExtendedDate(WeekState.date);
      const from = selectedDate.isToday() ? new ExtendedDate().getTime() : selectedDate.setHours(0, 0, 0, 0);
      const until = selectedDate.setHours(24, 0, 0, 0);
      const activeZone = new ActiveZoneBookings(zid, from, until, true);
      await activeZone.init();
      activeZone.caluclateAvailableSlotsInTimeline();
      this.bookings = activeZone.bookings;
    },
    createHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },
    deleteHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },
    changeHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },
    navigateToCustomBooking() {
      this.$router.push({ path: `${this.$route.path}/custom`, query: this.$route.query });
    },
  },
};
</script>

<style lang="scss">
.booking-information--container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navigator-booking-wrapper {
  .accordion-container {
    .card {
      padding: 1rem;
    }

    .booking_items_container{
      padding: 1rem 0;
      cursor: pointer;
    }
  }

  .book_button_container {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
  }

  .booking_items_container {
    display: flex;
    justify-content: space-between;

    p, .button {
      margin: auto 0;
    }

    .booking_flex_container {
      gap: 1rem;
      display: flex;
      flex: 1;
      overflow: hidden;
      gap: 1rem;

      .orgianizer_container {
        margin: auto 0;
      }

      .icon-holder {
        margin: 0 0.5rem 0.25rem 0;
      }
    }
  }

  .booking_items_container--icon {
    margin: auto 0;
    gap: 0.5rem;
    display: flex;
    justify-content: space-between;

    svg {
      width: 1rem;
      fill: var(--primary-color);
      color: var(--primary-color);
    }
  }

  .no-padding-top > .card {
    padding-top: unset;
  }
}

</style>

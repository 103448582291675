<template>
  <div>
    <h6 class="normal-semi">
      {{ $t('Views.Book.quick_booking_suggestions') }}
    </h6>
    <div
      v-for="(suggestion, index) in suggestions"
      :key="index"
      class="padding padding-medium padding-bottom padding-top"
    >
      <Button
        :disabled="disabled"
        variant="pastel-primary"
        size="stretch"
        class="quick-booking--suggestion__button"
        @click="emitSuggestion ? $emit('onSuggestedBooking', suggestion) : createBooking(suggestion)"
      >
        <p class="normal-semi primary">
          {{ suggestion.title }}
        </p>
        <p class="normal-semi primary">
          {{ suggestion.time }}
        </p>
      </Button>
    </div>
    <div
      v-if="!suggestions.length"
      class="quick-booking--no_suggestions"
    >
      <h6 class="big-regular secondary padding padding-medium padding-all">
        {{ $t('Views.Book.no_booking_suggestions_text') }}
      </h6>
    </div>
  </div>
</template>

<script>
import Button from '@/components/button/button.vue';
import WeekState from '@/singletons/week.state.singleton';
import BookingSuggestionsService from '@/Services/Bookings/booking.suggestions.service';
import SocketService from '@/Services/Socket/socket.service';
import ErrorMessage from '@/Services/Error/Error';
import CustomEvent from '@/classes/custom.event.class';

export default {
  components: {
    Button,
  },
  props: {
    zone: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    emitSuggestion: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      suggestions: [],
      WeekState,
      eventListeners: [],
      createdBooking: null,
      timeoutRef: null,
      count: 0,
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'WeekState.date': function () {
      clearTimeout(this.timeoutRef);
      this.getSuggestions();
    },
    zone() {
      clearTimeout(this.timeoutRef);
      this.getSuggestions();
    },
  },
  async created() {
    this.getSuggestions();
    this.setUpBookingEventHandlers();
  },

  beforeDestroy() {
    clearTimeout(this.timeoutRef);
    this.eventListeners.forEach((l) => {
      window.removeEventListener(l.type, l.listener);
    });
    this.eventListeners = [];
  },

  methods: {
    async getSuggestions() {
      const { suggestions, apiResponse } = await BookingSuggestionsService.getSuggestions(this.zone.Zid);

      this.suggestions = suggestions;

      const wait = (apiResponse.validUntil - Date.now());
      if (wait) {
        this.startTimeOut(wait);
      }
    },

    startTimeOut(wait) {
      const ONE_MIN = 1000 * 60;
      const time = Math.max(wait, ONE_MIN);
      this.timeoutRef = setTimeout(() => {
        this.getSuggestions();
      }, time);
    },

    setUpBookingEventHandlers() {
      window.addEventListener(SocketService.events.booking_created, this.createHandle);
      window.addEventListener(SocketService.events.booking_deleted, this.deleteHandle);
      window.addEventListener(SocketService.events.booking_state, this.changeHandle);

      this.eventListeners.push({ type: SocketService.events.booking_created, listener: this.createHandle });
      this.eventListeners.push({ type: SocketService.events.booking_deleted, listener: this.deleteHandle });
      this.eventListeners.push({ type: SocketService.events.booking_state, listener: this.changeHandle });
    },

    createHandle(e) {
      if (e.data.TargetZid === this.zone.Zid && this.suggestions.length) {
        this.getSuggestions();
      }
    },

    deleteHandle(e) {
      if (e.data.TargetZid === this.zone.Zid && this.suggestions.length) {
        this.getSuggestions();
      }
    },

    changeHandle(e) {
      if (e.data.TargetZid === this.zone.Zid && this.suggestions.length) {
        this.getSuggestions();
      }
    },

    async createBooking(suggestion) {
      try {
        const data = await BookingSuggestionsService.createBooking(suggestion, this.zone.Zid);
        this.createdBooking = data;
        const evt = new CustomEvent(this.$t('Messages.created_booking'), 'global_error_message', 'success');
        evt.dispatch();
        this.$router.push({ name: 'agenda', query: { date: this.$route.query.date } });
      } catch (e) {
        let evtMsg;
        if (e.response && e.response.data) {
          evtMsg = ErrorMessage.getBookingError(e.response.data);
        } else evtMsg = ErrorMessage.getBookingError(e);
        const evt = new CustomEvent(evtMsg);
        evt.dispatch();
      }
    },
  },
};
</script>

<style lang="scss">
.quick-booking--suggestion__button {
  display: flex;
  justify-content: space-between;
  p {
    text-align: left;
    margin-bottom: 0px;
  }
}
</style>

<template>
  <div>
    <Header
      class="flex"
      :back="true"
      :text="backText"
      :route="route"
      :show-indicator="!!stateActive"
      :show-resource-info="true"
      :title="resourceName"
    >
      <h5 class="header-title">
        {{ resourceName }}
      </h5>
      <div
        class="booking-information--container"
        @click="navigateToInformation"
      >
        <StateIndicator v-if="stateActive" />
        <svg-icon
          :src="INFORMATION_ICON"
        />
      </div>
    </Header>
    <div class="scrollable">
      <Card>
        <WeeklyDateSelector
          :week-length="WeekState.weekLength"
          :week-start="WeekState.date"
          :booking-notifications="bookingNotifications"
          @date-selected="dateChanged"
          @week-changed="weekChanged"
        />
      </Card>
      <Card v-if="resource">
        <BookingTimeline
          v-if="loaded && ZoneState.hasLoadedOnce"
          :bookings="bookings"
          :resource="resource"
          :show-all="expanded"
        />
        <AccordionButton
          v-if="bookings.length > 2"
          class="show_more_button_container"
          :open="expanded"
          @clicked="expanded = !expanded"
        />
      </Card>
      <Card v-if="resource.Bookable">
        <SuggestionsComponent :zone="resource" />
      </Card>
      <Card v-if="!resource.Bookable && getInfoString()">
        <p>{{ getInfoString() }}</p>
      </Card>
      <Card position="bottom">
        <Button
          v-if="bookable"
          class="book_button_container"
          variant="primary"
          @click="navigateToCustomBooking"
        >
          {{ $t('Views.Book.custom_booking') }}
        </Button>
      </Card>
    </div>
  </div>
</template>

<script>
import WeekState from '@/singletons/week.state.singleton';
import ZoneState from '@/singletons/zone.state.singleton';
import ActiveZoneBookings from '@/classes/active.zone.bookings.class';
import BookingService from '@/Services/Bookings/bookings.service';

/** Components */
import Card from '@/components/card/card.vue';
import Header from '@/components/headers/header.vue';
import Button from '@/components/button/button.vue';
import StateIndicator from '@/components/state/state.indicator.component.vue';
import AccordionButton from '@/components/button/accordion.button.vue';
import SocketService from '@/Services/Socket/socket.service';
import WeeklyDateSelector from '@/components/weeklydateselector/weekly.dateselector.vue';
import UserService from '@/Services/User/user.service';
import { getBookingNotifications } from '@/functions/date.notification.functions';
import UserState from '@/singletons/user.state.singleton';
import BookingTimeline from './components/booking.timeline.vue';
import ExtendedDate from '../../classes/extended.date.class';
import SuggestionsComponent from './components/booking.suggestions.vue';
import QueryMixin from '../../mixins/query.mixin';

const INFORMATION_ICON = '/icons/information_circle.svg';

export default {
  components: {
    Header,
    Card,
    Button,
    BookingTimeline,
    StateIndicator,
    SuggestionsComponent,
    AccordionButton,
    WeeklyDateSelector,
  },
  mixins: [QueryMixin],
  data() {
    return {
      ZoneState,
      WeekState,
      status: ZoneState.ZONE_STATE,
      bookings: [],
      loaded: false,
      INFORMATION_ICON,
      expanded: false,
      eventListeners: [],
      bookingNotifications: [],
    };
  },
  computed: {
    stateActive() { return !!ZoneState.realmState.find((states) => states.Zid === this.Zid); },
    resource() { return ZoneState.activeResources[0]; },
    resourceName() { return this.resource?.Name; },
    bookable() { return this.resource?.Bookable; },
    backText() { return this.resource?.TypeName; },
    route() {
      return `/book/${this.$route.params.type}?ignoreType=false`;
    },
  },

  watch: {
    resource() {
      if (this.bookable) {
        this.setUpActiveZoneBookings();
      } else {
        this.bookings = [];
      }
    },
  },
  async created() {
    this.replaceQuery({ date: WeekState.date.getDateString() });
    const checkedDate = new ExtendedDate(WeekState.date.getDateString());
    await this.weekChanged(checkedDate);
  },
  mounted() {
    const { zid } = this.$route.params || 0;
    this.zid = Number(zid);
    if (this.bookable) {
      this.setUpActiveZoneBookings();
      this.setUpBookingEventHandlers();
    }

    this.loaded = true;
  },

  beforeDestroy() {
    this.eventListeners.forEach((l) => {
      window.removeEventListener(l.type, l.listener);
    });
    this.eventListeners = [];
  },

  methods: {
    async setUpActiveZoneBookings() {
      const { zid } = this.$route.params;
      const selectedDate = new ExtendedDate(WeekState.date);
      const from = selectedDate.isToday() ? new ExtendedDate().getTime() : selectedDate.setHours(0, 0, 0, 0);
      const until = selectedDate.setHours(24, 0, 0, 0);
      const activeZone = new ActiveZoneBookings(zid, from, until, true);
      await activeZone.init();
      activeZone.caluclateAvailableSlotsInTimeline();
      this.bookings = activeZone.bookings;
    },
    getInfoString() {
      switch (ZoneState.zoneStates[this.resource.Zid]?.Status) {
        case this.status.AVAILABLE:
          return this.$t('Views.Book.available_to_use');
        case this.status.AWAY:
          return this.$t('Views.Book.not_available_to_use');
        case this.status.OCCUPIED:
        case this.status.UNKNOWN:
        default:
          return '';
      }
    },
    setUpBookingEventHandlers() {
      window.addEventListener(SocketService.events.booking_created, this.createHandle);
      window.addEventListener(SocketService.events.booking_deleted, this.deleteHandle);
      window.addEventListener(SocketService.events.booking_state, this.changeHandle);

      this.eventListeners.push({ type: SocketService.events.booking_created, listener: this.createHandle });
      this.eventListeners.push({ type: SocketService.events.booking_deleted, listener: this.deleteHandle });
      this.eventListeners.push({ type: SocketService.events.booking_state, listener: this.changeHandle });
    },

    createHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },
    deleteHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },
    changeHandle(e) {
      if (e.data.TargetZid === this.zid && this.bookable) {
        this.setUpActiveZoneBookings();
      }
    },

    navigateToCustomBooking() {
      this.$router.push({ path: `${this.$route.path}/custom`, query: this.$route.query });
    },
    navigateToInformation() {
      this.$router.push({ path: `${this.$route.path}/information`, query: this.$route.query });
    },
    async weekChanged(date) {
      WeekState.changeWeek(date);
      this.bookingNotifications = [];
      await Promise.all([
        UserService.fetchUserBookings(WeekState.weekStart.getTime(), WeekState.weekEnd.getTime()).then(() => {
          this.bookingNotifications = getBookingNotifications(UserState.bookings, WeekState.weekLength);
        }),
        BookingService.getBookingsOfDay(date),
      ]);
      this.setUpActiveZoneBookings(this.zid);
      this.updateQuery({ date: WeekState.date.getDateString() });
    },
    dateChanged(date) {
      WeekState.date = date;
      this.setUpActiveZoneBookings(this.zid);
      this.updateQuery({ date: WeekState.date.getDateString() });
    },
  },
};
</script>

<style lang="scss" scoped>
.book_button_container {
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
}
.show_more_button_container {
  display: flex;
  justify-content: space-around;
  margin: 1rem auto;
  margin-bottom: 0;
}

.booking-information--container {
  // width: 50px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>

class ColleagueState {
  constructor() {
    this.colleagues = [];
  }

  destroy() {
    this.colleagues = [];
  }
}

export default new ColleagueState();
